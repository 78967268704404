import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import TemplatesUbluxAPI from '../../../api/ublux/templatesUblux';

export const state = {
  records: {},
  allRecords: {},
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

function handleError(error) {
  if (error.response && error.response.data && error.response.data.meta) {
    const errorMeta = error.response.data.meta;

    if (errorMeta.error) {
      let text = errorMeta.error;
      if (typeof errorMeta.error === 'string') {
        text = JSON.parse(errorMeta.error);
      }

      if (typeof text === 'string') {
        throw new Error(text);
      } else {
        const data = { ...text };
        throw new Error(data.error.error_user_msg);
      }
    } else if (errorMeta.developer_message) {
      throw new Error(errorMeta.developer_message);
    } else {
      throw new Error(error);
    }
  } else {
    throw new Error(error);
  }
}

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getTemplates: _state => {
    return _state.records;
  },
  getAllTemplates: _state => {
    return _state.allRecords;
  },
};

export const actions = {
  get: async ({ commit, rootGetters }) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isFetching: true });
    try {
      const fromPhoneNumbers =
        await rootGetters['phoneNumbersFrom/getPhoneNumbersFrom'];
      const fetchPromises = fromPhoneNumbers.map(async phone => {
        const response = await TemplatesUbluxAPI.getTemplates(
          phone.phoneNumber
        );
        return {
          phoneNumber: phone.phoneNumber,
          templates: response.data.items.filter(
            item => item.status === 'approved'
          ),
        };
      });

      const result = await Promise.all(fetchPromises);

      const templatesByPhoneNumber = result.reduce(
        (acc, { phoneNumber, templates }) => {
          acc[phoneNumber.replace('+', '')] = templates;
          return acc;
        },
        {}
      );

      commit(types.SET_TEMPLATES, templatesByPhoneNumber);
    } catch (error) {
      handleError(error);
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isFetching: false });
    }
  },
  getAll: async ({ commit, state: $state }, { from, sync }) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isFetching: true });
    try {
      const isTemplates = $state.allRecords[from.replace('+', '')];

      if (sync || !isTemplates || isTemplates.length <= 0) {
        const response = await TemplatesUbluxAPI.getTemplates(from);
        const templates = response.data.items;

        commit(types.SET_TEMPLATES_ALL, {
          data: templates,
          from,
        });
      } else {
        commit(types.UPDATE_TEMPLATES_ALL);
      }
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, templateObj) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isCreating: true });
    try {
      const { data } = await TemplatesUbluxAPI.create(
        templateObj.payload,
        templateObj.from
      );

      if ('success' in data && !data.success) {
        const error =
          data.errors && data.errors.length > 0
            ? data.errors[0].error?.error_user_msg ||
              data.errors[0].error?.message
            : 'Error al crear plantilla';
        throw new Error(error);
      }

      commit(types.ADD_TEMPLATES, {
        data,
        from: templateObj.from,
      });
    } catch (error) {
      handleError(error);
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isCreating: false });
    }
  },
  // update: async ({ commit }, { id, ...updateObj }) => {
  //   commit(types.SET_TEMPLATES_UI_FLAG, { isUpdating: true });
  //   try {
  //     const response = await TemplatesUbluxAPI.update(id, updateObj);
  //     AnalyticsHelper.track(TEMPLATES_EVENTS.UPDATE_TEMPLATES);
  //     commit(types.EDIT_TEMPLATES, response.data);
  //   } catch (error) {
  //     throw new Error(error);
  //   } finally {
  //     commit(types.SET_TEMPLATES_UI_FLAG, { isUpdating: false });
  //   }
  // },
  delete: async ({ commit }, { templateId, from }) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isDeleting: true });
    try {
      await TemplatesUbluxAPI.delete(templateId);
      commit(types.DELETE_TEMPLATES, { templateId, from });
    } catch (error) {
      handleError(error);
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isDeleting: false });
    }
  },
  synchronize: async ({ commit, dispatch }, fromPhoneNumber) => {
    commit(types.SET_TEMPLATES_UI_FLAG, { isUpdating: true });
    try {
      await TemplatesUbluxAPI.synchronize(fromPhoneNumber);
      await dispatch('get');
      await dispatch('getAll', { from: fromPhoneNumber, sync: true });
    } catch (error) {
      handleError(error);
    } finally {
      commit(types.SET_TEMPLATES_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [types.SET_TEMPLATES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_TEMPLATES_ALL]($state, { data, from }) {
    $state.allRecords[from.replace('+', '')] = [...data];
    $state.allRecords = { ...$state.allRecords };
  },
  [types.UPDATE_TEMPLATES_ALL]($state) {
    $state.allRecords = { ...$state.allRecords };
  },
  [types.ADD_TEMPLATES]($state, { data, from }) {
    const fromNumber = from.replace('+', '');
    $state.records[fromNumber] = [...$state.records[fromNumber], data];
    $state.allRecords[fromNumber] = [...$state.allRecords[fromNumber], data];
    $state.records = { ...$state.records };
    $state.allRecords = { ...$state.allRecords };
  },
  [types.SET_TEMPLATES]: MutationHelpers.set,
  [types.DELETE_TEMPLATES]($state, { templateId, from }) {
    $state.records[from] = [
      ...$state.records[from].filter(record => record.id !== templateId),
    ];
    $state.allRecords[from] = [
      ...$state.allRecords[from].filter(record => record.id !== templateId),
    ];

    $state.records = { ...$state.records };
    $state.allRecords = { ...$state.allRecords };
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
