import { frontendURL } from '../../../helper/URLHelper';
const SettingsContent = () => import('../settings/Wrapper.vue');
const Index = () => import('./Index.vue');
const CreateStepWrap = () => import('./Create/Index.vue');
const CreateCampaign = () => import('./Create/CreateCampaign.vue');
const SelectTemplate = () => import('./Create/SelectTemplate.vue');
const LoadContacts = () => import('./Create/LoadContacts.vue');
const VariablesTemplate = () => import('./Create/VariablesTemplate.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.HEADER',
        icon: 'campaign',
        viewBox: '0 -960 960 960',
        headerButtonText: 'CAMPAIGN.LIST.ADD_CAMPAIGN',
      },
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'campaigns_list',
          roles: ['administrator', 'agent'],
          component: Index,
        },
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'campaigns_new',
              component: CreateCampaign,
              roles: ['administrator', 'agent'],
            },
            {
              path: 'selected_template',
              name: 'campaigns_selected_template',
              component: SelectTemplate,
              roles: ['administrator', 'agent'],
            },
            {
              path: 'load_contacts',
              name: 'campaigns_load_contacts',
              component: LoadContacts,
              roles: ['administrator', 'agent'],
            },
            {
              path: 'finish',
              name: 'campaigns_finish',
              component: VariablesTemplate,
              roles: ['administrator', 'agent'],
            },
          ],
        },
      ],
    },
  ],
};
