import Vue from 'vue';
import * as types from '../mutation-types';
import UserNotificationSettings from '../../api/userNotificationSettings';
import UserNotificationSettingsUbluxAPI from '../../api/ublux/userNotificationSettingsUblux';

const state = {
  record: {},
  uiFlags: {
    isFetching: false,
    isUpdating: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getSelectedEmailFlags: $state => {
    return $state.record.selected_email_flags;
  },
  getSelectedPushFlags: $state => {
    return $state.record.selected_push_flags;
  },
  getSelectedMobileFlags: $state => {
    return $state.record.selected_mobile_flags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, { isFetching: true });
    try {
      let mobileUblux = [];
      const response = await UserNotificationSettings.get();
      const responseUblux =
        await UserNotificationSettingsUbluxAPI.getNotificationMobile();

      if (
        responseUblux.data?.data &&
        Object.values(responseUblux.data?.data).length > 0
      ) {
        const objectEntries = Object.entries(
          responseUblux.data.data.custom_attributes.ublux.mobile_notifications
        );

        const filtersUblux = objectEntries.filter(
          ([, value]) => value === true
        );

        if (filtersUblux && filtersUblux.length > 0) {
          mobileUblux = filtersUblux.map(([key]) => key);
        }
      }

      commit(types.default.SET_USER_NOTIFICATION, {
        ...response.data,
        selected_mobile_flags: mobileUblux,
      });
      commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, {
        isFetching: false,
      });
    }
  },

  update: async (
    { commit },
    { selectedEmailFlags, selectedPushFlags, selectedMobileFlags }
  ) => {
    commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, { isUpdating: true });
    try {
      let mobileUblux = [];
      const response = await UserNotificationSettings.update({
        notification_settings: {
          selected_email_flags: selectedEmailFlags,
          selected_push_flags: selectedPushFlags,
        },
      });
      const updateUblux =
        await UserNotificationSettingsUbluxAPI.updateNotificationMobile({
          custom_attributes: {
            ublux: {
              mobile_notifications: selectedMobileFlags,
            },
          },
        });

      if (
        updateUblux.data?.data &&
        Object.values(updateUblux.data?.data).length > 0
      ) {
        const objectEntries = Object.entries(
          updateUblux.data.data.custom_attributes.ublux.mobile_notifications
        );

        const filtersUblux = objectEntries.filter(
          ([, value]) => value === true
        );

        if (filtersUblux && filtersUblux.length > 0) {
          mobileUblux = filtersUblux.map(([key]) => key);
        }
      }

      commit(types.default.SET_USER_NOTIFICATION, {
        ...response.data,
        selected_mobile_flags: mobileUblux,
      });
      commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, {
        isUpdating: false,
      });
    } catch (error) {
      commit(types.default.SET_USER_NOTIFICATION_UI_FLAG, {
        isUpdating: false,
      });
      throw error;
    }
  },
};

export const mutations = {
  [types.default.SET_USER_NOTIFICATION_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_USER_NOTIFICATION]: ($state, data) => {
    Vue.set($state, 'record', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
