/* global axios */

class TimezonesUbluxAPI {
  // eslint-disable-next-line class-methods-use-this
  getTimezones() {
    return axios.ublux.get('/v1/Admin/TimeZone/Get');
  }
}

export default new TimezonesUbluxAPI();
