import Auth from '../api/auth';

const parseErrorCode = error => Promise.reject(error);

export default axios => {
  const { accessToken, refreshToken } = Auth.getAuthDataUblux();
  const ubluxApi = axios.create({
    baseURL: 'https://api.ublux.com',
  });

  // Add Auth Headers to requests if logged in
  if (Auth.hasAuthCookieUblux()) {
    ubluxApi.defaults.headers.common.Authorization = accessToken;
    ubluxApi.defaults.headers.common['Content-Type'] = 'application/json';
  }

  // Interceptor para manejar errores 401 y refrescar el token
  ubluxApi.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;

      // Si recibes un error 401 y no es un retry
      if (error.response.status === 401 && !originalRequest.retryAttempt) {
        originalRequest.retryAttempt = true;

        try {
          // Intenta refrescar el token usando el refresh token
          const { data } = await axios.get(
            `https://api.ublux.com/v1/Public/Authorization/RefreshToken/${refreshToken}`,
            {
              headers: {
                Authorization: accessToken,
              },
            }
          );

          // Actualiza el token de acceso en el sistema Auth
          Auth.setAuthDataUblux({
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
            accountId: data.id_account,
            roles: data.roles,
          });

          // Actualiza el header Authorization con el nuevo token
          ubluxApi.defaults.headers.common.Authorization = data.access_token;
          originalRequest.headers.Authorization = data.access_token;

          // Reintenta la solicitud original con el nuevo token
          return ubluxApi(originalRequest);
        } catch (refreshError) {
          // Si el refresh token falla, se ejecuta el logout y redirige a login
          await Auth.logout();
          return Promise.reject(refreshError);
        }
      }

      return parseErrorCode(error);
    }
  );
  return ubluxApi;
};
