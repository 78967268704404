import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import VoipNumberPhoneUbluxAPI from '../../../api/ublux/voipNumberPhoneUblux';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getVoipPhoneNumbers: _state => {
    return _state.records;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_VOIP_NUMBER_PHONE_UBLUX_UI_FLAG, { isFetching: true });
    try {
      const response = await VoipNumberPhoneUbluxAPI.getVoipPhoneNumbers();
      commit(types.SET_VOIP_NUMBER_PHONE_UBLUX, response.data.records);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_VOIP_NUMBER_PHONE_UBLUX_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_VOIP_NUMBER_PHONE_UBLUX_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_VOIP_NUMBER_PHONE_UBLUX]: MutationHelpers.set,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
