import types from '../../mutation-types';
import HubspotUbluxAPI from '../../../api/ublux/hubspotUblux';

export const state = {
  accounts: [],
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getAccounts: _state => {
    return _state.accounts;
  },
};

export const actions = {
  getAccount: async ({ commit, state: $state }, inbox_id) => {
    commit(types.SET_HUBSPOT_UBLUX_UI_FLAG, { isFetching: true });
    try {
      const isInbox = $state.accounts.findIndex(
        value => Number(value.inbox_id) === inbox_id
      );

      if (isInbox < 0) {
        const response = await HubspotUbluxAPI.getAccount(inbox_id);
        commit(types.SET_HUBSPOT_ACCOUNT_UBLUX, response.data?.data);
      }
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_HUBSPOT_UBLUX_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_HUBSPOT_UBLUX_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_HUBSPOT_ACCOUNT_UBLUX](_state, data) {
    _state.accounts.push(data);
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
