/* global axios */

class VoipNumberPhoneUbluxAPI {
  // eslint-disable-next-line class-methods-use-this
  getVoipPhoneNumbers() {
    return axios.ublux.post(
      '/v1/Admin/VoipNumberPhone/GetMany?pageNumber=0&pageSize=200'
    );
  }
}

export default new VoipNumberPhoneUbluxAPI();
