var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col w-full gap-2 max-h-[312px]"},[_c(_setup.CalendarAction,{attrs:{"view-mode":_setup.YEAR,"calendar-type":_vm.calendarType,"button-label":_setup.yearName(
        _vm.calendarType === _setup.START_CALENDAR ? _vm.startCurrentDate : _vm.endCurrentDate,
        _setup.MONTH
      )},on:{"set-view":_setup.setViewMode,"prev":_setup.onClickPrev,"next":_setup.onClickNext}}),_vm._v(" "),_c('div',{staticClass:"grid w-full grid-cols-3 gap-x-3 gap-y-2 auto-rows-[61px]"},_vm._l((_setup.months),function(month,index){return _c('button',{key:index,staticClass:"p-2 text-sm font-medium text-center text-slate-800 dark:text-slate-50 w-[92px] h-10 rounded-lg py-2.5 px-2 hover:bg-slate-75 dark:hover:bg-slate-700",class:{
        'bg-woot-600 dark:bg-woot-600 text-white dark:text-white hover:bg-woot-500 dark:bg-woot-700':
          index === _setup.activeMonthIndex,
      },on:{"click":function($event){return _setup.selectMonth(index)}}},[_vm._v("\n      "+_vm._s(month)+"\n    ")])}),0)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }