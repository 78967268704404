const campaigns = () => ({
  parentNav: 'campaigns',
  routes: [
    'campaigns_list',
    'campaigns_new',
    'campaigns_selected_template',
    'campaigns_load_contacts',
    'campaigns_finish',
  ],
  menuItems: [],
});

export default campaigns;
